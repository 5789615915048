import { render, staticRenderFns } from "./kpiView.vue?vue&type=template&id=31aede18&scoped=true&"
import script from "./kpiView.vue?vue&type=script&lang=js&"
export * from "./kpiView.vue?vue&type=script&lang=js&"
import style0 from "./kpiView.vue?vue&type=style&index=0&id=31aede18&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31aede18",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VContainer,VIcon,VRadio,VRadioGroup,VSimpleTable,VSnackbar,VSwitch,VTab,VTabItem,VTabs,VTabsItems,VTextField})
